<template>
  <div>
    <b-row>
      <b-col sm="12" xl="9">
        <b-card>
          <validation-observer ref="form">
            <b-form>
              <b-row>
                <b-col sm="12">
                  <h3>SEO</h3>
                </b-col>
                <b-col sm="12">
                  <b-row>
                    <b-col sm="12">
                      <b-form-group>
                        <label for="about-image">OG Image <span class="text-red">*</span></label>
                        <div 
                          class="flex flex-col preview-image dashed"
                          :hidden="fileUrl !== ''"
                          id="dropzone"
                          :class="loading ? 'pointer-none' : ''"
                        >
                          <div
                            class="flex flex-col items-center"
                            @click="$refs.file.$refs.input.click()"
                          >
                            <feather-icon
                              icon="UploadCloudIcon"
                              size="30"
                            />
                            <div v-if="!loading" class="flex flex-col">
                              <span class="font-18px">Size dimension 800x800 px</span>
                              <span>(Size not exceed 10 MB, PNG, JPG)</span>
                            </div>
                            <span v-else class="font-18px">Loading...</span>
                          </div>
                        </div>
                        <div
                          class="relative"
                          :hidden="fileUrl === ''"
                        >
                          <b-img
                            :src="fileUrl"
                            class="image-full"
                          />
                          <div 
                            class="close-button"
                            @click="resetFileUpload"
                          >
                            <feather-icon
                              icon="XIcon"
                              size="16"
                            />
                          </div>
                        </div>
                        <validation-provider
                          name="image"
                          rules="required"
                          hidden
                        >
                          <b-form-file
                            ref="file"
                            :disabled="loading"
                            v-model="file"
                            placeholder="Choose file"
                            accept=".jpg, .png"
                            @input="inputImageRenderer"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col sm="12">
                  <b-tabs pills>
                    <b-tab active>
                      <template #title>
                        <div class="flex items-center">
                          <span>English</span>
                        </div>
                      </template>
                      <b-form-group>
                        <label for="content-english-slug">Page URL (Slug)</label>
                        <b-input-group prepend="https://akarahospitality.com/">
                          <b-form-input v-model="content.english.url" />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <label for="content-english-title">Meta Tag - Title</label>
                        <b-form-input
                          v-model="content.english.title"
                          placeholder="Meta Tag - Title"
                          :state="content.english.title.length >= 75 ? false : null"
                          :class="content.english.title.length >= 75 ? 'text-danger' : ''"
                        />
                        <span class="font-12px">{{content.english.title.length}}/75</span>
                      </b-form-group>
                      <b-form-group>
                        <label for="content-english-description">Meta Tag - Description</label>
                        <b-form-textarea
                          v-model="content.english.description"
                          rows="5"
                          placeholder="Meta Tag - Description"
                          :state="content.english.description.length >= 150 ? false : null"
                          :class="content.english.description.length >= 150 ? 'text-danger' : ''"
                        />
                        <span class="font-12px">{{content.english.description.length}}/150</span>
                      </b-form-group>
                    </b-tab>
                    <b-tab>
                      <template #title>
                        <div class="flex items-center">
                          <span>Thai</span>
                        </div>
                      </template>
                      <b-form-group>
                        <label for="content-thai-slug">Page URL (Slug)</label>
                        <b-input-group prepend="https://akarahospitality.com/">
                          <b-form-input v-model="content.thai.url" />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <label for="content-thai-title">Meta Tag - Title</label>
                        <b-form-input
                          v-model="content.thai.title"
                          placeholder="Meta Tag - Title"
                          :state="content.thai.title.length >= 75 ? false : null"
                          :class="content.thai.title.length >= 75 ? 'text-danger' : ''"
                        />
                        <span class="font-12px">{{content.thai.title.length}}/75</span>
                      </b-form-group>
                      <b-form-group>
                        <label for="content-thai-description">Meta Tag - Description</label>
                        <b-form-textarea
                          v-model="content.thai.description"
                          rows="5"
                          placeholder="Meta Tag - Description"
                          :state="content.thai.description.length >= 150 ? false : null"
                          :class="content.thai.description.length >= 150 ? 'text-danger' : ''"
                        />
                        <span class="font-12px">{{content.thai.description.length}}/150</span>
                      </b-form-group>
                    </b-tab>
                    <b-tab>
                      <template #title>
                        <div class="flex items-center">
                          <span>Chinese</span>
                        </div>
                      </template>
                      <b-form-group>
                        <label for="content-chinese-slug">Page URL (Slug)</label>
                        <b-input-group prepend="https://akarahospitality.com/">
                          <b-form-input v-model="content.chinese.url" />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group>
                        <label for="content-chinese-title">Meta Tag - Title</label>
                        <b-form-input
                          v-model="content.chinese.title"
                          placeholder="Meta Tag - Title"
                          :state="content.chinese.title.length >= 75 ? false : null"
                          :class="content.chinese.title.length >= 75 ? 'text-danger' : ''"
                        />
                        <span class="font-12px">{{content.chinese.title.length}}/75</span>
                      </b-form-group>
                      <b-form-group>
                        <label for="content-chinese-description">Meta Tag - Description</label>
                        <b-form-textarea
                          v-model="content.chinese.description"
                          rows="5"
                          placeholder="Meta Tag - Description"
                          :state="content.chinese.description.length >= 150 ? false : null"
                          :class="content.chinese.description.length >= 150 ? 'text-danger' : ''"
                        />
                        <span class="font-12px">{{content.chinese.description.length}}/150</span>
                      </b-form-group>
                    </b-tab>
                  </b-tabs>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
    <div class="fixed-footer justify-end">
      <div>
        <b-button
          variant="outline-secondary"
          @click="() => this.$router.go(0)"
        >
          <span>Cancel</span>
        </b-button>
        <b-button
          class="ml-1"
          variant="success"
          @click="handleUpdate"
        >
          <span>Update</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BInputGroup, BRow, BCol, BCard, BCardText, BFormTextarea, BFormFile, BImg, BBadge, BTab, BTabs
} from 'bootstrap-vue'
import Dropzone from 'dropzone'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, regex } from '@validations'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import settingStoreModule from './settingStoreModule'
import useSetting from './useSetting'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormTextarea,
    BBadge,
    BFormFile,
    BImg,
    BTab,
    BTabs,

    vSelect,
  },
  props: {},
  data() {
    return {
      required,
      email,
      regex,
      loading: false,
      file: null,
      fileUrl: '',
      content: {
        english: {
          url: 'about',
          title: '',
          description: '',
        },
        thai: {
          url: 'about',
          title: '',
          description: '',
        },
        chinese: {
          url: 'about',
          title: '',
          description: '',
        },
      }
    }
  },
  methods: {
    async inputImageRenderer(file) {
      if (!file) return;

      const form = new FormData();
      form.append('files', file);
      
      this.loading = true;
      const { data, status } = await this.uploadImage(form);

      if (status !== 200) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error upload image',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return;
      }

      this.fileUrl = data[0];
      this.loading = false;
    },
    resetFileUpload() {
      this.fileUrl = '';
    },
    async handleUpdate() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      const { status } = await this.updateSetting({
        img: this.fileUrl,
        info: this.content,
      })
      if (status !== 200) {
        this.makeToast(false)
        return;
      }

      this.makeToast(true)
    },
    makeToast(success) {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class={success ? 'text-success' : 'text-danger'}
              icon={success ? 'CheckCircleIcon' : 'XCircleIcon'}
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class={success ? 'ml-1 text-success' : 'ml-1 text-danger'}>{success ? 'Update Succeeded!' : 'Updated failed!' }</h4>
              <div class="ml-1 font-14px">{success ? 'Setting have been updated.' : 'Unexpected error occurred.'}</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
  },
  async created() {
    const app = document.getElementById('app')
    const appLoading = document.getElementById('loading-bg')
    const appLoadingImage = document.querySelector('#loading-bg > img')
    if (appLoading) {
      app.style.overflow = 'hidden'
      appLoading.style.zIndex = '9'
      appLoading.style.display = 'block'
      appLoadingImage.src = `/loading.gif?${Math.random()}`
    }
    
    try {
      const { data } = await this.fetchSetting();
      this.fileUrl = data.img;
      this.file = new File([''], 'banner.jpg')
      this.content = data.info;
      if (appLoading) {
        app.style.overflow = 'auto'
        appLoading.style.display = 'none'
      }
    } catch (error) {
      this.toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching content',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  },
  mounted() {
    const dropzoneElement = document.getElementById('dropzone')
    if (dropzoneElement) {
      const dropZone = new Dropzone(dropzoneElement, { url: '/', autoProcessQueue: false, disablePreviews: true });
      dropZone.on('addedfile', async (file) => {
        this.$refs.file.setFiles([file])
      })
    }
  },
  setup() {
    const SETTING_STORE_MODULE_NAME = 'app-about-setting'

    // Register module
    if (!store.hasModule(SETTING_STORE_MODULE_NAME)) store.registerModule(SETTING_STORE_MODULE_NAME, settingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTING_STORE_MODULE_NAME)) store.unregisterModule(SETTING_STORE_MODULE_NAME)
    })

    const { fetchSetting, updateSetting, uploadImage } = useSetting();
    const toast = useToast()

    return {
      toast,
      fetchSetting,
      updateSetting,
      uploadImage,
    }
  },
}
</script>