import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSetting() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/aboutSettingGet')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSetting(_, setting) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/aboutSettingUpdate`, { ...setting })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImage(_, images) {
      return new Promise((resolve, reject) => {
        axios
          .post('/uploader', images, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
